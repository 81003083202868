// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
//Html editor
@import "~vue2-editor/dist/vue2-editor.css";

html {
    scroll-behavior: smooth;
    will-change: transform;
}

img {
    filter: blur(0);
    -webkit-filter: blur(0);
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
}


// Body

body {
    //font-family: 'Montserrat', sans-serif;
    font-family: 'Poppins', sans-serif;
    min-height: 100vh;
}

main {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

h1 {
    text-align: center;
    font-weight: 500;
    font-size: 50px;
    font-family: 'Poppins', sans-serif;;
}

h2 {
    font-size: 2rem;
}

@media screen and (max-width: 992px) {
    h1 {
        font-size: 40px;
    }

    h2 {
        font-size: 25px;
    }

    h3 {
        font-size: 20px;
    }
}

@media screen and (max-width: 768px) {
    h1 {
        font-size: 30px;
    }

    h3 {
        font-size: 20px;
    }
}

@media screen and (max-width: 500px) {
    h1 {
        font-size: 28px;
    }

    form {
        padding: 0 !important;
    }
}

// Nav

.navbar {
    padding: 0;
    //position: fixed !important;
    top: 0;
    background-color: rgba(256, 256, 256, 1) !important;
    width: 100%;
    z-index: 1000;
    transition: height 0.5s ease;
}

.bigNav {
    height: 90px;

    filter: blur(0);
    -webkit-filter: blur(0);
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
}


.smallNav {
    height: 62px;

    filter: blur(0);
    -webkit-filter: blur(0);
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
}

.logo-nav {
    height: 80px;
    transition: height 0.5s ease;

    // Zabrání rozmazanému obrázku v chromu
    filter: blur(0);
    -webkit-filter: blur(0);
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
}

.smallLogo {
    height: 54px;
}

.nav-item {
    font-size: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.nav-link {
    margin-top: 9px;
    margin-bottom: 9px;
    font-weight: 550;
    font-family: "Poppins", sans-serif;
    color: black !important;
    padding: 0 10px;
    transition: color 0.5s ease;
}

.nav-link:hover {
    color: rgba(139, 0, 0, 0.8) !important;
    margin-bottom: 9px;
}

.link-underline {
    color: transparent;
    transition: border-bottom-color 0.5s ease;
    border-bottom: 2px solid transparent;
    //width: 70%;
    margin: 0 auto;
}

.nav-link:hover ~ .link-underline {
    width: 70%;
    border-bottom: 2px solid rgba(139, 0, 0, 0.8);
    margin: 0 auto;
    height: 0;
}

@media (max-width: 768px) {
    .navbar-collapse {
        background-color: white !important;
        border-top: 2px solid rgba(0,0,0,0.1);
        margin-top: -4px !important;
    }

    .navbar-brand {
        padding-left: 15px;
    }

    .bigNav {
        height: 62px;
    }

    .logo-nav {
        height: 56px;
    }

    .navbar-toggler {
        margin-right: 15px;
        border-color: rgba(176,16,11,255) !important;
        border-width: 2px;
        padding: 0.35rem 0.7rem !important;
    }

    .toggler {
        color: rgba(176,16,11,255);
        font-size: 14px;
        font-weight: 450;
    }

    .togglerbars {
        font-size: 19px;
        color: rgba(176,16,11,255);
    }

    .nav-item {
        padding-left: 30px;
        border-bottom: 1px solid rgba(139, 0, 0, 0.1);
    }

    .nav-item:last-child {
        padding-left: 0px;
        background-color: rgba(139, 0, 0, 1);
        //background-color:     rgba(176,16,11,255) !important;
    }


    .btn-schuzka {
        margin-left: 15px !important;
        width: 100%;
        height: 100%;
        filter: brightness(100%) !important;
        background-color: rgba(139, 0, 0, 0.8) !important;
        //background-color:     rgba(176,16,11,255) !important;
        color: white !important;
        border-radius: 0px !important;
        padding: 3px !important;
        //margin-left: 20px;
    }

    .btn-schuzka:hover {
        background-color: rgba(139, 0, 0, 0.8) !important;
        filter: brightness(100%) !important;
    }

    .nav-link:hover ~ .link-underline {
        width: 18%;
        border-bottom: 2px solid rgba(139, 0, 0, 0.8);
        margin-left: 0;
        height: 0;
    }
}
//.link-underline:hover {
//    width: 70%;
//    border-bottom: 2px solid #8B0000;
//    margin: 0 auto;
//}

//.nav-item {
//    border-bottom: 1px solid #8B0000;
//}
//.link-container::before{
//    content:'';
//    position:absolute;
//    width: 100%;
//    height: 0;
//    left:0;
//    bottom:0px; /*Change this to increase/decrease distance*/
//    border-bottom: 2px solid #000;
//}


//.linkname:after {
//    content : "";
//    position: absolute;
//    left    : 0;
//    bottom  : 0;
//    height  : 1px;
//    width   : 90%;  /* or 100px */
//    border-bottom:1px solid #8B0000;
//}

.btn-schuzka {
    background-color: rgba(139, 0, 0, 0.8);
    color: white !important;
    border-color: white;
    //font-size: 1.2rem !important;
    border-radius: 25px ;
    padding: 10px 15px !important;
    margin-left: 20px;
    transition: background-color 0.5s ease;
}

.btn-schuzka:hover {
    background-color: #8B0000 ;
    filter: brightness(90%) ;
    border-color: white;
    color: white !important;
}

// Welcome

#welcome {
    background: url("../images/uvod.jpg") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
    height: 100vh;
    width: 100%;
    color: white;
}

.mask {
    background-color: rgba(0, 0, 0, 0.6);
    height: 100vh;
    width: 100%;
    z-index: 1;
}

.title {
    font-size: 60px;
    text-shadow: 0 1px 2px black;
    font-weight: 300;
    margin-bottom: 30px;
}

.under-title {
    font-size: 26px;
    text-shadow: 0 1px 1px black;
    font-weight: 250;
    margin-bottom: 30px;
    line-height: 1.6;
}

.btn-success {
    background-color: rgba(0, 0, 0, 0.15) !important;
    border-color: white;
    font-size: 1.2rem !important;
    border-radius: 25px !important;
    padding: 5px 40px;
    transition: background-color 0.5s ease;
}

.btn-success:hover {
    //background-color: rgba(139, 0, 0, 1) !important;
    background-color: rgba(0, 0, 0, 1) !important;
    filter: brightness(105%);
    border-color: white;
    color: white !important;
}

.btn-success:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: white;
}

.btn-success:active {
    border-color: white !important;
}

.btn-primary:hover,.btn-primary:focus,.btn-primary:active,
.btn-primary:active:focus:not(:disabled):not(.disabled),
.btn:focus, .btn:active, .btn:hover{
    box-shadow: none!important;
    border-color: white;
    outline: 0 !important;
}

.introbox {
    width: 100%;
    height: 300px;
    display: flex;
    background-color: #e8e6e7;
}

//.introcontainer {
//    display: flex;
//    margin: auto;
//
//    //border: 1px dotted #8B0000;
//    background-color: white;
//    padding: 40px 30px;
//    //stroke='%238B0000FF'
//    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='34' ry='34' stroke='black' stroke-width='11' stroke-dasharray='15%2c 15%2c 1' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
//    border-radius: 34px;
//}

.introcontainer {
    display: flex;
    margin: auto;
    position: relative;
    background-color: white;
    border-radius: 5px;
    padding: 40px 30px;
    max-width: 1370px
}

.introcontainer:after {
    display: block;
    content: "";
    width: 70px;
    height: 50px;
    position: absolute;
    top: -4px;
    right: -4px;
    border-top: 8px solid #8B0000;
    border-right: 8px solid #8B0000;
    border-radius: 2px;
}

.introcontainer:before {
    display: block;
    content: "";
    width: 70px;
    height: 50px;
    position: absolute;
    bottom: -4px;
    left: -4px;
    border-bottom: 8px solid #8B0000;
    border-left: 8px solid #8B0000;
    border-radius: 2px;
}

.introtext {
    margin: auto;
    text-align: center;

    font-size: 26px;
    font-weight: 400;
    line-height: 1.6;
}
@media screen and (max-width: 1450px) {
    .introtext {
        font-size: 20px;
    }

    .introcontainer {
        max-width: 1200px;
    }
}

@media screen and (max-width: 1200px) {
    .introtext {
        font-size: 18px;
    }

    .introcontainer {
        max-width: 990px;
    }
}

@media screen and (max-width: 991px) {
    .title {
        font-size: 44px;
    }

    .under-title {
        font-size: 24px;
    }
}
@media screen and (max-width: 768px){
    .title {
        font-size: 26px;
    }

    .btn-success {
        font-size: 25px;
    }

    .under-title {
        font-size: 16px;
    }
}

@media screen and (max-width: 630px){
    .introtext {
        font-size: 17px;
    }

    .introcontainer {
        max-width: 500px;
    }
}

@media screen and (max-width: 510px){
    .introtext {
        font-size: 17px;
    }

    .introcontainer {
        max-width: 400px;
    }

    .introbox {
        height: 400px;
    }
}

@media screen and (max-width: 420px){
    .introtext {
        font-size: 17px;
    }

    .introcontainer {
        max-width: 350px;
    }

    .introbox {
        height: 460px;
    }
}


// Dot pattern
// Colors
$bg-color: #ffffff;
$dot-color: #e8e6e7;

// Dimensions
$dot-size: 3px;
$dot-space: 22px;

.dots {
    background:
        linear-gradient(90deg, $bg-color ($dot-space - $dot-size), transparent 1%) center,
        linear-gradient($bg-color ($dot-space - $dot-size), transparent 1%) center,
        $dot-color;
    background-size: $dot-space $dot-space;
}

//Down arrow in a circle thing
.inner-circle{
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border-style: solid;
    border-width: 6px;
    line-height: 70px;
    border-color: #e8e6e7;
    padding: 18px 12px;
    background-color: rgba(0, 0, 0, 0);
}

.play {
    border-style: solid;
    box-sizing: border-box;
    width: 34px;
    height: 34px;
    border-width: 17px 0px 17px 30px;
    border-color: transparent transparent transparent #e8e6e7;
    transform: rotate(90deg);
}

// Projects

.mask-projects {
    //background-color: rgba(0, 0, 0, 0.4);
    //height: 100%;
    //width: 100%;
    //z-index: 8;
    overflow: hidden;
}

.p-col {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    max-height: 368px;
}

.p-name {
    font-size: 40px;
    font-weight: 500;
    color: white;
    z-index: 2;
    position: absolute;
    bottom: 20%;
    left: 6.5%;
    text-shadow: 0 1px 1px black;
}

.p-date {
    font-size: 20px;
    color: white;
    z-index: 2;
    position: absolute;
    top: 80%;
    left: 7%;
    text-shadow: 0 1px 1px black;
}

.p-image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
    max-height: 368px;
    object-fit:cover;
}

.p-button {
    z-index: 2;
    position: absolute;
    top: 79%;
    right: 5%;
    padding: 5px 50px;
}

@media screen and (max-width: 1000px){
    .p-row {
        flex-direction: column !important;
        //width: 50%;
        margin: 0;
    }

    .mask-projects {
        flex-direction: row;
        display: flex;
    }

}

@media screen and (max-width: 768px){
    .p-row {
        flex-direction: row !important;
        width: 100%;
    }

    .mask-projects {
        flex-direction: column;
        display: flex;
    }

}

@media screen and (max-width: 500px){
    .p-name {
        font-size: 25px;
        //top: 65%;
        //left: 8%;
    }

    .p-image {
        right: 5%;
    }

    .p-button {
        //right: 5%;
        //top: 78%
    }

    .p-date {
        font-size: 20px;
        //top: 78%;
    }

}

// Boxes

#boxes {
    padding-top: 40px;
    padding-bottom: 20px;
    background-color: #e8e6e7;
}

.box-container {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    justify-content: center;
}

.b-top {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}

.b-img {
    width: 50px;
    height: 50px;
    margin-left: auto;
}


.b-head {
    font-weight: 500;
    font-size: 20px;
    text-align: left;
    margin: auto 0 auto 25px;
    width: 70%;
    color: white;
}

.b-text {
    font-size: 15px;
    color: white;
    white-space: pre-line;
    text-align: left;
}

.box {
    background-color: #8B0000;
    position: relative;
    box-sizing: border-box;
    text-align: center;
    padding: 25px 15px;
    width: 31.33%;
    //width: 19%;
    margin-bottom: 1%;
    margin-right: 1%;
}

.b1 {
    margin-right: 1%;
}

.b2 {
    margin-right: 1%;
}

.b3 {
    margin-right: 0;
}

@media screen and (min-width: 1300px){
    .box {
        min-height: 270px;
    }
}

@media screen and (max-width: 1400px){
    .box-container {
        justify-content: center;
    }
}

@media screen and (max-width: 991px){
    .box {
        width: 49%;
    }

    .b1 {
        margin-right: 1% !important;
    }

    .b2 {
        margin-right: 0;
    }


    .b-head {
        margin-left: 0px !important;
    }

    ul {
        padding-left: 15px;
    }

    .box-container {
        justify-content: center;
    }
}

@media screen and (max-width: 768px){
    .b-head {
        font-size: 15px;
    }
}

@media screen and (max-width: 550px){
    .box {
        width: 100%;
        margin-right: 0;
    }

    .b-head {
        margin-left: 5px;
        font-size: 20px;
    }

    ul {
        padding-left: 20px;
    }

    .b1 {
        margin-right: 0% !important;
    }
}

// Contacts

$width: 197px;
$font-size: 197px;

#foto {
    border-radius: 100%;
    width: $width;
    height: auto;
    margin: 0 auto 30px;
}

.icon {
    //font-size: 197px;
    font-size: $font-size;
    margin: 0 auto 30px;
}

.pic {
    min-height: 227px;
    display: flex;
}

@media screen and (max-width: 1300px){

    .icon {
        font-size: 160px;
    }

    $font-size: 160px;
    $width: 160px;
}

@media screen and (max-width: 1000px){
    //#foto {
    //    width: 60%;
    //}

}

@media screen and (max-width: 768px){
    #foto {
        width: 200px;
        margin: auto;
    }

    .icon {
        font-size: 180px;
        margin: auto;
    }



    $font-size: 180px;
    $width: 180px;
}

@media screen and (max-width: 450px){
    #foto {
        width: 180px;
    }

    .icon {
        font-size: 160px;
    }

    $font-size: 160px;
    $width: 160px;
}

// Form

.form {
    max-width: 800px;
}

.form .form-control {
    border: 1px solid black;
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);
}

.form label {
    font-size: 1.2rem;
}

.form form .btn-success {
    max-width: 212px;
}

@media screen and (max-width: 450px){
    .form label {
        font-size: 1rem;
    }

    .form form .btn-success {
        margin: 20px auto 0;
    }
}


// Single project

.fa-arrow-left {
    color: rgba(256, 256, 256, 0.2);
    font-size: 60px;
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 2;
}

.modal-backdrop {
    height: 100% !important;
}

.fa-arrow-left:hover {
    color: rgba(256, 256, 256, 0.7);
}

// Admin

.admin-nav {
    min-height: 50px;
}

.projects-link {
    padding: 30px;
    font-size: 20px;
    background-color: white;
    border: 2px solid gray;
    border-radius: 50px;
    width: 400px;
    margin: 50px auto 0;
    text-align: center;
    color: black;
}

.fa-arrow-right {
    font-size: 20px;
    color: black;
    margin-left: 15px;
}

// Footer

footer {
    color: white;
    background-color: #8B0000;
    font-size: 13px;
    margin-top: auto;
}

footer a {
    font-weight: bold;
    color: #fffff0;
}

footer a:hover {
    color: #fffff0;
}

.softiciimg {
    height: 25px;
}

footer .container {
    max-width: 1600px;
    padding: 0.5rem !important;
}

//.softici {
//    margin-right: -26px
//}
